import { ReactElement, useEffect } from "react";

import { useRouter } from "next/router";

import { AuthenticatedLayout } from "@/components/layouts";

const NextIndexPage = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace(`/dashboard`);
  }, []);

  return null;
};

NextIndexPage.getLayout = (page: ReactElement) => (
  <AuthenticatedLayout title="Dashboard">{page}</AuthenticatedLayout>
);

export default NextIndexPage;
